(function ($) {
    'use strict';
    $(function () {
        $('.js-menu-trigger,.js-menu-screen').on('click touchstart', function (e) {
            $('.js-menu,.js-menu-screen').toggleClass('is-visible');
            e.preventDefault();
        });
        $('.nav-link').on('click', function () {
            $('.js-menu, .js-menu-screen').toggleClass('is-visible');
        });
        $('.nav-link').on('click', function (){
            var section = $(this).attr('class');
            section = section.replace('nav-link','').trim();
            $('html, body').animate({
                scrollTop: $("#"+section).offset().top
            }, 800);
        });
        $('.footer-navigation li a').on('click', function (){
            var section = $(this).attr('href');
            $('html, body').animate({
                scrollTop: $(section).offset().top
            }, 800);
        });
    });

    //Google Maps
    var markerPosition = new google.maps.LatLng(47.3611425, 8.5517984);
    var contentString = '<address>' +
        '<span class="company">transit</span>' +
        '<span class="street">Seefeldstrasse 69</span>' +
        '<span class="plz">CH-8008 Zürich</span>' +
        '<span class="tel">Tel: 043 488 36 13</span>' +
        '<span class="tel"><a href="mailto:info@transitweb.ch">info@transitweb.ch</a></span>' +
        '</address>';
    var mapOptions = {
        center: new google.maps.LatLng(47.364553, 8.5517984),
        zoom: 15,
        disableDefaultUI: true,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    var map = new google.maps.Map(document.getElementById("map"),
        mapOptions);

    var marker = new google.maps.Marker({
        position: markerPosition,
        map: map,
        title: "Seefeldstrasse 69"
    });

    var infowindow = new google.maps.InfoWindow({
        content: contentString
    });

    infowindow.open(map, marker);
})(window.jQuery);